import React, { useCallback } from 'react';
import { Typography } from '@material-ui/core';
import { Rating as MuiRating } from '@material-ui/lab';

interface RatingProps {
  stars: number;
  onSubmit(field: string, value: unknown): void;
}

const Rating: React.FC<RatingProps> = ({ stars, onSubmit }) => {
  const handleRatingChange = useCallback(
    (_, value) => {
      onSubmit('quality', value);
    },
    [onSubmit]
  );

  return (
    <div>
      <Typography>Qualidade</Typography>
      <MuiRating
        name="quality"
        value={stars}
        size="small"
        onChange={handleRatingChange}
      />
    </div>
  );
};

export default Rating;
