import React, { useCallback } from 'react';
import { Typography, TextField, MenuItem, IconButton } from '@material-ui/core';
import { IoMdPeople } from 'react-icons/io';
import { FiFilter, FiMinusCircle } from 'react-icons/fi';

import { useStyles } from './styles';
import FilterElement from './FilterElement';
import Rating from './Rating';
import { Filters, FilterName } from './props';

interface LeftMenuProps {
  onFilterChange: (name: FilterName, value: unknown) => void;
  filters?: Filters;
}

const LeftMenu: React.FC<LeftMenuProps> = ({ filters, onFilterChange }) => {
  const classes = useStyles();

  const handleStarClick = useCallback(
    (starNumber: number) => {
      onFilterChange('quality', starNumber);
    },
    [onFilterChange]
  );

  const handleNoIndicationDaysChange = useCallback(
    (days: string | null) => {
      if (filters?.noIndicationIn !== days) {
        onFilterChange('noIndicationIn', days);
      }
    },
    [filters?.noIndicationIn, onFilterChange]
  );

  return (
    <div className={classes.leftMenu}>
      <Typography component="div" className="menu-title">
        <IoMdPeople />
        Indikers
      </Typography>
      <Typography component="div" className="menu-section">
        <FiFilter />
        Filtros
      </Typography>
      <FilterElement
        title="Indikers novos"
        name="new_indiker"
        onChange={onFilterChange}
        active={filters?.new_indiker}
      />
      <FilterElement
        title="Sem contato"
        name="no_contact"
        onChange={onFilterChange}
        active={filters?.no_contact}
      />
      <FilterElement
        title="Indicou 1 empresa"
        name="oneIndication"
        onChange={onFilterChange}
        active={filters?.oneIndication}
      />
      <FilterElement
        title="Indiker master"
        name="indikerMaster"
        onChange={onFilterChange}
        active={filters?.indikerMaster}
      />
      <FilterElement
        title="Indiker filho"
        name="indikerChild"
        onChange={onFilterChange}
        active={filters?.indikerChild}
      />
      <FilterElement
        title="Indikers inativos"
        name="inactive"
        onChange={onFilterChange}
        active={filters?.inactive}
      />

      <Typography component="div" className="menu-section">
        <FiFilter />
        Tempo sem indicar
        <IconButton
          className="remove-btn"
          title="Remover filtro"
          onClick={() => handleNoIndicationDaysChange(null)}
        >
          <FiMinusCircle />
        </IconButton>
      </Typography>
      <TextField
        name="no_indications_in"
        select
        fullWidth
        value={filters?.noIndicationIn || ''}
        defaultValue=""
        onChange={(ev) => handleNoIndicationDaysChange(ev.target.value)}
      >
        {[15, 30, 60].map((item) => (
          <MenuItem key={`menu_${item}`} value={item}>
            {item} dias
          </MenuItem>
        ))}
      </TextField>

      <Rating stars={filters?.quality || 0} onSubmit={handleStarClick} />
    </div>
  );
};

export default LeftMenu;
