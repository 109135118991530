import React, { useEffect, useState } from 'react';
import {
  Card,
  IconButton,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { FaSearch } from 'react-icons/fa';

import { useStyles } from './styles';
import View from './View';
import TableData from '../../components/TableData/index';
import WithdrawStatus from '../../components/WithdrawStatus/index';
import Avatar from '../../components/Avatar/index';
import { fullName, getDateBr, getCurrencyBr } from '../../utils/format';
import { WithdrawDTO } from '../../dtos/WithdrawDTO';
import { Skeleton } from '@material-ui/lab';

interface TableProps {
  withdraws: WithdrawDTO[];
  currentPage: number;
  rowsPerPage: number;
  total: number;
  loading: boolean;
  setCurrentPage(page: number): void;
  setRowsPerPage(pages: number): void;
  onUpdate(): void;
}

const Table: React.FC<TableProps> = ({
  withdraws,
  currentPage,
  rowsPerPage,
  total,
  loading,
  setCurrentPage,
  setRowsPerPage,
  onUpdate,
}) => {
  const [selected, setSelected] = useState<number | null>(null);
  const [viewing, setViewing] = useState<WithdrawDTO | null>(null);
  const classes = useStyles();

  useEffect(() => {
    if (selected) {
      const selectedWithdraw = withdraws.find(
        (withdraw) => withdraw.id === selected
      );
      if (selectedWithdraw) setViewing(selectedWithdraw);
    } else {
      setViewing(null);
    }
    return () => setViewing(null);
  }, [selected, withdraws]);

  return (
    <>
      <TableContainer component={Card} className={classes.tableContainer}>
        {withdraws.length === 0 ? (
          <Typography component="div" className="no-results">
            Sem resultados
          </Typography>
        ) : (
          <MuiTable size="small">
            <TableHead>
              <TableRow>
                <TableCell>Nome do indiker</TableCell>
                <TableCell align="center">Previsão de pagamento</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Valor</TableCell>
                <TableCell align="center" />
              </TableRow>
            </TableHead>
            <TableBody>
              {withdraws.map((withdraw) => {
                return (
                  <TableRow key={`withdraw_${withdraw.id}`}>
                    <TableData loading={loading} classname="name-cell">
                      <Avatar
                        firstname={withdraw.user.firstname}
                        lastname={withdraw.user.lastname}
                        imageUrl={withdraw.user.image_url}
                        size={29}
                      />
                      {fullName(
                        withdraw.user.firstname,
                        withdraw.user.lastname
                      )}
                    </TableData>
                    <TableData loading={loading} align="center">
                      {getDateBr(withdraw.payment_date)}
                    </TableData>
                    <TableData loading={loading} align="center">
                      <WithdrawStatus withdrawId={withdraw.status} />
                    </TableData>
                    <TableData loading={loading} align="center">
                      {getCurrencyBr(withdraw.total)}
                    </TableData>
                    <TableData loading={loading} align="center">
                      <IconButton onClick={() => setSelected(withdraw.id)}>
                        <FaSearch size={20} />
                      </IconButton>
                    </TableData>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                {loading ? (
                  <TableCell colSpan={6}>
                    <Skeleton
                      width="300px"
                      height={40}
                      style={{ float: 'right' }}
                    />
                  </TableCell>
                ) : (
                  <TablePagination
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    labelRowsPerPage="Resultados por página"
                    colSpan={6}
                    count={total}
                    page={currentPage}
                    onPageChange={(_, newPage) => setCurrentPage(newPage)}
                    onRowsPerPageChange={(ev) =>
                      setRowsPerPage(parseInt(ev.target.value, 10))
                    }
                  />
                )}
              </TableRow>
            </TableFooter>
          </MuiTable>
        )}
      </TableContainer>
      {viewing && (
        <View
          data={viewing}
          open={Boolean(selected)}
          onClose={() => setSelected(null)}
          onUpdate={onUpdate}
        />
      )}
    </>
  );
};

export default Table;
