import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';

import Routes from './routes';
import { theme } from './styles/theme';
import { AuthProvider } from './context/auth';
import { DataProvider } from './context/data';
import { LoadingProvider } from './context/loading';
import { ToastProvider } from './context/toast';

const App: React.FC = () => (
  <ThemeProvider theme={theme}>
    <AuthProvider>
      <BrowserRouter>
        <LoadingProvider>
          <ToastProvider>
            <DataProvider>
              <Routes />
            </DataProvider>
          </ToastProvider>
        </LoadingProvider>
      </BrowserRouter>
    </AuthProvider>
  </ThemeProvider>
);

export default App;
