import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, InputAdornment, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

import { useStyles } from './styles';
import Logo from '../../components/Logo';
import { useToast } from '../../context/toast';
import { useAuth } from '../../context/auth';
import { emailValidator } from '../../utils/validators';

interface LoginFormData {
  email: string;
  password: string;
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required('E-mail obrigatório')
    .test('Email', 'E-mail inválido', (value) => emailValidator(value)),
  password: Yup.string()
    .required('Senha obrigatória')
    .min(8, 'Mínimo de 8 caracteres'),
});

const Login: React.FC = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { signIn } = useAuth();
  const history = useHistory();
  const { addToast } = useToast();
  const classes = useStyles();

  const handleShowPassword = useCallback(() => {
    setShowPassword((oldShowPassword) => !oldShowPassword);
  }, []);

  const handleSubmit = useCallback(
    async (values: LoginFormData) => {
      try {
        await signIn(values);
        history.push('/');
      } catch (error) {
        console.error(error);
        addToast({
          message: 'Erro de autenticação. Tente novamente',
          type: 'error',
        });
      }
    },
    [history, addToast, signIn]
  );

  const formik = useFormik<LoginFormData>({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  return (
    <div className={classes.root}>
      <Card className={classes.container}>
        <Logo color="primary" />
        <form onSubmit={formik.handleSubmit} className={classes.form}>
          <TextField
            id="email"
            name="email"
            label="E-mail"
            variant="filled"
            fullWidth
            className={classes.input}
            error={formik.touched.email && !!formik.errors.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            helperText={(formik.touched.email && formik.errors.email) || null}
          />
          <TextField
            id="password"
            name="password"
            label="Senha"
            type={showPassword ? 'text' : 'password'}
            variant="filled"
            fullWidth
            className={classes.input}
            error={formik.touched.password && !!formik.errors.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            helperText={
              (formik.touched.password && formik.errors.password) || null
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" onClick={handleShowPassword}>
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </InputAdornment>
              ),
            }}
          />
          <div className={classes.linkContainer}>
            <Button
              type="button"
              variant="text"
              className={classes.link}
              onClick={() => {
                history.push('/recuperar-senha');
              }}
            >
              ESQUECEU SUA SENHA?
            </Button>
          </div>
          <Button type="submit" variant="contained" fullWidth>
            Entrar
          </Button>
        </form>
      </Card>
    </div>
  );
};

export default Login;
