import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Card,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useData } from '../../context/data';
import { WithdrawDTO } from '../../dtos/WithdrawDTO';

interface StatusFormProps {
  data: WithdrawDTO;
  onSubmit(values: StatusFormData): Promise<void>;
}

export interface StatusFormData {
  status: number;
  observation: string;
}

const validationSchema = Yup.object().shape({
  status: Yup.string(),
  observation: Yup.string().when('status', {
    is: '0',
    then: Yup.string().required('Motivo requerido para cancelamento'),
  }),
});

const StatusForm: React.FC<StatusFormProps> = ({ data, onSubmit }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { withdrawStatuses } = useData();

  const formik = useFormik<StatusFormData>({
    initialValues: {
      status: data.status,
      observation: '',
    },
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    setShowConfirmation(formik.values.status !== data.status);
    return () => setShowConfirmation(false);
  }, [data.status, formik.values.status]);

  const handleFormReset = useCallback(() => {
    formik.setValues(formik.initialValues);
    formik.setTouched(formik.initialTouched);
    formik.setErrors(formik.initialErrors);
  }, [formik]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography className="title">Status</Typography>
      <TextField
        id="status"
        name="status"
        select
        variant="filled"
        onChange={formik.handleChange}
        value={formik.values.status}
      >
        {withdrawStatuses.map((item) => (
          <MenuItem key={`ws_${item.id}`} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </TextField>
      <Card
        className="confirmation"
        style={{
          display: showConfirmation ? 'block' : 'none',
        }}
      >
        <Typography className="confirmation-label">
          Confirmar alteração
        </Typography>
        <div
          className="observation"
          style={{
            display: formik.values.status === 0 ? 'block' : 'none',
          }}
        >
          <TextField
            id="observation"
            name="observation"
            label="Observação"
            variant="filled"
            fullWidth
            multiline
            error={formik.touched.observation && !!formik.errors.observation}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.observation}
            helperText={
              (formik.touched.observation && formik.errors.observation) || null
            }
          />
        </div>
        <div className="buttons">
          <Button variant="contained" onClick={handleFormReset}>
            Não
          </Button>
          <Button type="submit" variant="contained" className="primary">
            Sim
          </Button>
        </div>
      </Card>
    </form>
  );
};

export default StatusForm;
