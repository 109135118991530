import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.background.default,
    width: '100%',
    height: '100vh',
  },
  container: {
    padding: '60px 30px',
    width: 380,
  },
  form: {
    marginTop: 60,
  },
  input: {
    marginBottom: 18,
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: '20px',
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: '37px',
  },
  textContainer: {
    marginBottom: '37px',
    textAlign: 'center',
  },
  text: {
    fontSize: '13px',
    padding: '0 10px',
    textAlign: 'center',
    marginBottom: '37px',
  },
  resendBtn: {
    fontSize: '16px',
    fontWeight: 700,
    color: theme.palette.primary.main,
    marginLeft: 10,
  },
  counter: {
    fontSize: '8px !important',
    color: '#7E7E7E !important',
    marginLeft: 3,
  },
  tokenField: {
    marginBottom: 19,
    '& .MuiInputBase-input': {
      textAlign: 'center',
      fontSize: 20,
      fontWeight: 700,
      letterSpacing: 8,
    },
  },
  field: {
    marginBottom: 62,

    '& .MuiOutlinedInput-root': {
      borderRadius: 10,
    },
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {
    width: '45%',
    fontWeight: 700,
    textTransform: 'unset',
    padding: 20,
  },
}));
