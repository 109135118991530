import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { useStyles } from './styles';
import LeftMenu from './LeftMenu';
import Data from './Data';
import Annotations from './Annotations';
import Infos from './Infos';
import Indications from './Indications';
import { FilterName, initialValues } from './props';
import BackLink from '../../components/BackLink';
import { useToast } from '../../context/toast';
import api from '../../services/api';
import { IndikerDTO } from '../../dtos/IndikerDTO';

const Indiker: React.FC = () => {
  const [indiker, setIndiker] = useState<IndikerDTO | null>(null);
  const [filters, setFilters] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const { id } = useParams<{ id: string }>();
  const { addToast } = useToast();
  const classes = useStyles();

  const updateIndiker = useCallback((updatedIndiker: IndikerDTO) => {
    setIndiker(updatedIndiker);
  }, []);

  useEffect(() => {
    if (indiker && !indiker.crm.viewed) {
      const data = { viewed: true };
      api
        .put<IndikerDTO>(`/admin/indikers/${indiker.id}`, data)
        .then((result) => {
          setIndiker(result.data);
        })
        .catch((error) => {
          console.error(error);
          addToast({
            message: 'Erro definindo a visualização do indiker',
            type: 'error',
          });
        });
    }
  }, [addToast, indiker]);

  useEffect(() => {
    setLoading(true);

    api
      .get<IndikerDTO>(`/admin/indikers/${id}`)
      .then((result) => {
        setIndiker(result.data);
      })
      .catch((error) => {
        console.error(error);
        addToast({
          message: 'Error carrgando os dados do indiker',
          type: error,
        });
      })
      .finally(() => {
        setLoading(false);
      });

    return () => setIndiker(null);
  }, [addToast, id]);

  const handleFilterChange = useCallback((name: FilterName, value: unknown) => {
    setFilters((oldFilters) => ({ ...oldFilters, [name]: value }));
  }, []);

  return (
    <div className={classes.root}>
      <LeftMenu filters={filters} onFilterChange={handleFilterChange} />
      <div className={classes.content}>
        <BackLink />
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            {indiker && !loading ? (
              <Data indiker={indiker} onUpdate={updateIndiker} />
            ) : (
              <Skeleton variant="rect" width="100%" height="300px" />
            )}
          </Grid>
          <Grid item xs={12} md={5}>
            {indiker && !loading ? (
              <Annotations indiker={indiker} onUpdate={updateIndiker} />
            ) : (
              <Skeleton variant="rect" width="100%" height="300px" />
            )}
          </Grid>
          <Grid item xs={12} md={3}>
            <Infos indikerId={id} />
          </Grid>
        </Grid>
        <Indications
          indikerId={id}
          filters={filters}
          onFilterChange={handleFilterChange}
        />
      </div>
    </div>
  );
};

export default Indiker;
