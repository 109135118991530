import React, { useCallback, useState } from 'react';
import { Button, InputAdornment, TextField } from '@material-ui/core';
import { FaDownload, FaSearch } from 'react-icons/fa';
import fileSaver from 'file-saver';

import { useStyles } from './styles';
import { FilterName, Filters } from './props';
import api from '../../services/api';

interface ActionsProps {
  search: string;
  onSubmit(name: FilterName, value: unknown): void;
  filters: Filters;
}

const Actions: React.FC<ActionsProps> = ({ search, onSubmit, filters }) => {
  const [inputValue, setInputValue] = useState(search);
  const classes = useStyles();

  const handleSearch = useCallback(
    (ev: React.FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      onSubmit('search', inputValue);
    },
    [inputValue, onSubmit]
  );

  const handleExport = useCallback(() => {
    const params = new URLSearchParams();
    params.set('export', 'true');
    Object.entries(filters).forEach(([key, value]) => {
      if (value) params.set(key, value);
    });

    api
      .get('/admin/indikers', { params, responseType: 'arraybuffer' })
      .then((result) => {
        const contentDisposition: string =
          result.headers['content-disposition'];
        const filename = contentDisposition.split('="')[1].replace('"', '');
        const blob = new Blob([result.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        fileSaver.saveAs(blob, filename);
      });
  }, [filters]);

  return (
    <div className={classes.actions}>
      <form onSubmit={handleSearch}>
        <TextField
          name="search"
          variant="filled"
          label="Nome do indiker"
          value={inputValue}
          onChange={(ev) => setInputValue(ev.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <FaSearch />
              </InputAdornment>
            ),
          }}
        />
      </form>
      {/* <Button className={classes.exportBtn} onClick={handleExport}>
        <FaDownload />
        Exportar
      </Button> */}
    </div>
  );
};

export default Actions;
