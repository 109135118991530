import React, { useCallback } from 'react';
import {
  Card,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from '@material-ui/core';

import { useStyles } from './styles';
import StatusForm, { StatusFormData } from './StatusForm';
import PaymentData from './PaymentData';
import { useToast } from '../../context/toast';
import api from '../../services/api';
import { fullName, getDateBr, getCurrencyBr } from '../../utils/format';
import { WithdrawDTO } from '../../dtos/WithdrawDTO';

interface ViewProps {
  data: WithdrawDTO;
  open: boolean;
  onClose(): void;
  onUpdate(): void;
}

const View: React.FC<ViewProps> = ({ data, open, onClose, onUpdate }) => {
  const { addToast } = useToast();
  const classes = useStyles();

  const handleSubmit = useCallback(
    async (values: StatusFormData) => {
      try {
        const updatedData = { status: values.status };
        if (values.status === 0) {
          Object.assign(updatedData, { observation: values.observation });
        }

        await api.put(`/admin/withdraw-payments/${data.id}`, updatedData);
        onUpdate();
        addToast({
          message: 'Pagamento atualizado',
          type: 'success',
        });
      } catch (error) {
        console.error(error);
        addToast({
          message: 'Erro atualizando o pagamento',
          type: 'error',
        });
      }
    },
    [addToast, data, onUpdate]
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      className={classes.viewContainer}
    >
      {data && (
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Typography className="title">Nome do Indiker:</Typography>
              <Typography className="name">
                {fullName(data.user.firstname, data.user.lastname)}
              </Typography>
              <PaymentData data={data.user.bank_account} />
            </Grid>
            <Grid item xs={4}>
              <StatusForm data={data} onSubmit={handleSubmit} />
              <div className="cards">
                <Card>
                  <Typography className="title">
                    Previsão de pagamento
                  </Typography>
                  <Typography className="data">
                    {getDateBr(data.payment_date)}
                  </Typography>
                </Card>
                <Card className="primary-card">
                  <Typography className="title">Valor:</Typography>
                  <Typography className="value">
                    {getCurrencyBr(data.total)}
                  </Typography>
                </Card>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default View;
