import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
  useContext,
} from 'react';

import { useAuth } from './auth';
import { useToast } from './toast';
import api from '../services/api';
import { IndicationStatusDTO } from '../dtos/IndicationDTO';
import { TagDTO } from '../dtos/IndikerDTO';
import { ContextData, indikerCrmStatuses, withdrawStatuses } from './dataProps';

/**
 * Contexto para fornecer dados estáticos da API
 */
const DataContext = createContext<ContextData>({} as ContextData);

/**
 * Provider para usar o DataContext
 */
const DataProvider: React.FC = ({ children }) => {
  const [indicationStatuses, setIndicationStatuses] = useState<
    IndicationStatusDTO[]
  >([]);
  const [tagsList, setTagsList] = useState<TagDTO[] | null>(null);
  const { isLogged } = useAuth();
  const { addToast } = useToast();

  const getIndicationStatuses = useCallback(async () => {
    try {
      const params = new URLSearchParams();
      params.set('active', '1');
      const result = await api.get('/admin/indication-statuses', { params });
      setIndicationStatuses(result.data);
    } catch (error) {
      console.error(error);
      addToast({
        message: 'Erro obtendo os status da indicação',
        type: 'error',
      });
    }
  }, [addToast]);

  const getTagsList = useCallback(async () => {
    try {
      const result = await api.get('/admin/tags');
      setTagsList(result.data);
    } catch (error) {
      console.error(error);
      addToast({
        message: 'Erro carregando a lista de tags',
        type: 'error',
      });
    }
  }, [addToast]);

  useEffect(() => {
    if (isLogged && !indicationStatuses.length) {
      getIndicationStatuses();
    }
  }, [getIndicationStatuses, indicationStatuses, isLogged]);

  useEffect(() => {
    if (isLogged && tagsList === null) {
      getTagsList();
    }
  }, [getTagsList, isLogged, tagsList]);

  return (
    <DataContext.Provider
      value={{
        indicationStatuses,
        indikerCrmStatuses,
        tagsList,
        withdrawStatuses,
        getIndicationStatuses,
        getTagsList,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

/**
 * Hook para carregar os dados estáticos deste contexto (tags e statuses)
 */
const useData = (): ContextData => {
  const context = useContext(DataContext);

  if (!context) {
    throw new Error('useData must be used within a DataProvider');
  }

  return context;
};

export { useData, DataProvider };
