import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  leftMenu: {
    width: 230,
    paddingLeft: 30,
    '& .menu-title': {
      marginTop: 100,
      fontSize: '16px',
      color: '#707070',
      '& svg': {
        marginRight: 10,
      },
    },
    '& .menu-section': {
      marginTop: 25,
      color: '#000000',
      fontSize: '14px',
      fontWeight: 500,
      textTransform: 'uppercase',
      '& svg': {
        marginRight: 10,
      },
      '& .remove-btn': {
        fontSize: '14px',
        color: theme.palette.error.main,
        padding: 0,
        margin: '0 0 8px 5px',
        '& svg': {
          marginRight: 0,
        },
      },
    },
    '& .menu-item': {
      display: 'flex',
      justifyContent: 'flex-start',
      flex: 1,
      marginLeft: 25,
      marginTop: 5,
      '& span': {
        fontSize: '14px',
        fontWeight: 400,
        color: '#707070',
      },
      '&.active': {
        '& span': {
          fontWeight: 700,
        },
      },
    },
    '& .star-btn': {
      border: 0,
      padding: 0,
      margin: 0,
      marginRight: 3,
      minWidth: 0,
    },
  },
  mainArea: {
    marginTop: 40,
    marginLeft: 20,
    marginRight: 35,
    flex: 1,
  },
  headerContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 25,
  },
  title: {
    fontSize: '20px',
    fontWeight: 500,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiInputLabel-root': {
      color: '#757575',
    },
    '& .MuiInputBase-input': {
      color: '#757575',
    },
    '& button': {
      marginLeft: 20,
      '& .MuiButton-label': {
        color: '#757575',
      },
    },
  },
  exportBtn: {
    '& svg': {
      marginRight: 10,
    },
  },
  tableContainer: {
    width: '100%',
    '& .name-cell': {
      display: 'flex',
      '& .MuiAvatar-root': {
        marginRight: 15,
      },
    },
    '& .no-results': {
      textAlign: 'center',
      padding: 20,
    },
    '& .MuiChip-root': {
      marginLeft: 10,
    },
    '& .MuiTableRow-hover': {
      cursor: 'pointer',
    },
    '& .inactive-user': {
      backgroundColor: 'rgba(255, 0, 0, 0.7)',
    },
  },
}));
