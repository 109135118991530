export interface Filters {
  new_indiker: boolean;
  noIndicationIn: 15 | 30 | 60 | null;
  no_contact: boolean;
  oneIndication: boolean;
  indikerMaster: boolean;
  indikerChild: boolean;
  inactive: boolean;
  quality: 0 | 1 | 2 | 3 | 4 | 5;
  search: string;
}

export type FilterName = keyof Filters;

export const initialFilters: Filters = {
  new_indiker: false,
  noIndicationIn: null,
  no_contact: false,
  oneIndication: false,
  indikerMaster: false,
  indikerChild: false,
  inactive: false,
  quality: 0,
  search: '',
};
