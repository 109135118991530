/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-extraneous-dependencies */
import {
  belongsTo,
  createServer,
  Factory,
  hasMany,
  Model,
  Request,
  Response,
  RestSerializer,
} from 'miragejs';
import jwt from 'jsonwebtoken';
import faker from 'faker';

import fakeData from './fakeData';
import { IndikerDTO } from '../dtos/IndikerDTO';
import { ListDTO } from '../dtos/ResultsDTO';
import { randomInteger, generateFakeDocument } from '../utils/helpers';
import { withdrawStatuses } from '../context/dataProps';

const jwtSecret = 'Testing_Secret';

faker.locale = 'pt_BR';

export default function fakeServer(): void {
  createServer({
    models: {
      user: Model,
      indiker: Model.extend({
        indications: hasMany(),
        tags: hasMany(),
        withdraws: hasMany(),
      }),
      indication: Model.extend({
        indiker: belongsTo(),
        contacts: hasMany(),
        statusTransactions: hasMany(),
      }),
      contact: Model.extend({
        indication: belongsTo(),
      }),
      tag: Model.extend({
        indiker: belongsTo(),
      }),
      indicationStatus: Model,
      statusTransaction: Model.extend({
        indicationStatus: belongsTo(),
        indication: belongsTo(),
      }),
      crmStatus: Model,
      withdraw: Model.extend({
        indiker: belongsTo(),
      }),
    },
    factories: {
      indiker: Factory.extend({
        firstname() {
          return faker.name.firstName();
        },
        lastname() {
          return faker.name.lastName();
        },
        document: generateFakeDocument('cpf'),
        phone() {
          return faker.phone.phoneNumber('(##) 9####-####');
        },
        email() {
          return faker.internet.exampleEmail(
            this.firstname.toString(),
            this.lastname.toString()
          );
        },
        image_url() {
          return faker.image.avatar();
        },
        image() {
          const path = this.image_url.toString().split('/');
          return path.pop();
        },
        crm() {
          const view = faker.datatype.boolean();
          const quality = !view ? 0 : randomInteger(0, 5);
          const crm_status = randomInteger(1, 2);
          const observation = !view ? null : faker.lorem.sentences(2);
          const tagIds = (): number[] => {
            if (!view) return [];
            const tagsQty = randomInteger(1, 3);
            const tagsList: number[] = [];
            for (let i = 0; i < tagsQty; i += 1) {
              let tagId = null;
              do {
                tagId = randomInteger(1, 5);
              } while (tagsList.includes(tagId));
              tagsList.push(tagId);
            }
            return tagsList;
          };
          return {
            viewed: view,
            quality,
            crm_status,
            observation,
            tagIds: tagIds(),
          };
        },
        active: true,
        created_at() {
          return faker.date.past(1);
        },
        updated_at: null,
        deleted_at: null,
        bank_account: {
          bank_id: 1,
          agency: '12345',
          account: '15978432',
          account_type: 1,
          account_owner_is_user() {
            return faker.datatype.boolean() ? 1 : 0;
          },
          transfer_type() {
            return randomInteger(1, 2);
          },
          account_owner_fisrtname() {
            return faker.name.firstName();
          },
          account_owner_lastname() {
            return faker.name.lastName();
          },
          account_owner_document: '11122233345',
          account_owner_phone() {
            return faker.phone.phoneNumber('(##) 9####-####');
          },
          pix_type() {
            return randomInteger(1, 3);
          },
          pix_key() {
            return faker.lorem.word();
          },
          bank() {
            const { banks } = fakeData;
            const bankId = randomInteger(1, banks.length);
            const bank = banks.find((item) => item.id === bankId);
            return bank;
          },
        },
        afterCreate(indiker: any, server: any) {
          const transactionsQty = randomInteger(0, 3);
          server.createList('withdraw', transactionsQty, { indiker });
        },
      }),
      indication: Factory.extend({
        name() {
          return faker.company.companyName(0);
        },
        document() {
          return generateFakeDocument('cnpj');
        },
        created_at() {
          return faker.date.past(1);
        },
        current_status() {
          return randomInteger(1, 5).toString();
        },
        afterCreate(indication: any, server: any) {
          const contactsQty = randomInteger(1, 3);
          server.createList('contact', contactsQty, { indication });
          server.create('statusTransaction', { indication });
        },
      }),
      contact: Factory.extend({
        firstname() {
          return faker.name.firstName();
        },
        lastname() {
          return faker.name.lastName();
        },
        email() {
          return faker.internet.exampleEmail(
            this.firstname.toString(),
            this.lastname.toString()
          );
        },
        phone() {
          return faker.phone.phoneNumber('(##) 9####-####');
        },
      }),
      statusTransaction: Factory.extend({
        indication_status_id: 0,
        created_at() {
          return faker.date.past(1);
        },
        afterCreate(statusTransaction: any) {
          const indication = statusTransaction.indication;
          const statusId = indication.current_status;
          statusTransaction.indication_status_id = statusId;
        },
      }),
      withdraw: Factory.extend({
        status() {
          const status = randomInteger(0, 3);
          return status;
        },
        status_name() {
          return '';
        },
        payment_date() {
          return faker.date.future(1);
        },
        total() {
          return faker.finance.amount(200, 800);
        },
        observation() {
          return !this.status ? faker.lorem.words : null;
        },
        afterCreate(wd: any) {
          const status = withdrawStatuses.find((item) => item.id === wd.status);
          if (status) wd.status_name = status.name;
        },
      }),
    },
    serializers: {
      indiker: RestSerializer.extend({
        include: ['indications', 'tags'],
        embed: true,
      }),
      indication: RestSerializer.extend({
        include: ['contacts', 'statusTransactions'],
        embed: true,
      }),
      indicationStatus: RestSerializer,
      crmStatus: RestSerializer,
      withdraw: RestSerializer.extend({
        include: ['indiker'],
        embed: true,
      }),
    },
    seeds(server) {
      server.create('user', fakeData.users[0] as Record<string, unknown>);
      fakeData.tags.forEach((tag) => {
        server.create('tag', tag as Record<string, unknown>);
      });
      fakeData.statuses.forEach((status) => {
        server.create('indicationStatus', status as Record<string, unknown>);
      });
      fakeData.crmStatuses.forEach((status) => {
        server.create('crmStatus', status as Record<string, unknown>);
      });
      for (let i = 0; i < 25; i += 1) {
        const indicationsQty = randomInteger(0, 25);
        server.create('indiker', {
          indications: server.createList('indication', indicationsQty),
        });
      }
    },
    routes() {
      this.urlPrefix = process.env.REACT_APP_API_URL || '';

      const headers = {
        'Content-Type': 'application/json',
      };

      this.post(
        '/admin/auth/login',
        (schema, request) => {
          const data = JSON.parse(request.requestBody) as {
            email: string;
            password: string;
          };
          const user = schema.db.users.findBy({ email: data.email });
          if (!user || data.password !== user?.password) {
            return new Response(401, headers, { error: 'Unauthorized' });
          }
          const responseData = {
            access_token: jwt.sign(user.id, jwtSecret),
            token_type: 'bearer',
            expires_in: 3600000,
          };
          return new Response(200, headers, responseData);
        },
        { timing: 2000 }
      );

      this.get(
        '/admin/auth/user',
        (schema, request) => {
          const errorResponse = new Response(400, headers, {
            error: 'Unauthorized',
          });
          const token = request.requestHeaders.Authorization.split(' ')[1];
          if (!token) return errorResponse;
          const id = jwt.decode(token) as string | null;
          if (!id) return errorResponse;
          const user = schema.db.users.find(id);
          if (!user) return errorResponse;
          return new Response(200, headers, { ...user });
        },
        { timing: 2000 }
      );

      this.get(
        '/admin/indikers',
        (schema, request: Request) => {
          const page = parseInt(request.queryParams.page, 10);

          if (page === 0) {
            console.error('======================');
            console.warn('Retornando arquivo CSV');
            console.error('======================');
          }

          const limit = parseInt(request.queryParams.limit, 10);
          const from = (page - 1) * limit;
          const to = from + limit;

          // @ts-ignore
          const result = schema.indikers.all();
          const data: any[] = result.models.map((child: any) => {
            const indiker = child.attrs;
            const indications: any = [];
            if (indiker.indicationIds) {
              indiker.indicationIds.forEach((indicationId: any) => {
                const indication = schema.find('indication', indicationId);
                indications.push(indication);
              });
            }
            return { ...indiker, indications };
          });
          const paginatedData = data.slice(from, to);
          const response: Partial<ListDTO<IndikerDTO>> = {
            data: paginatedData,
            current_page: page,
            total: data.length,
          };
          return new Response(200, headers, response);
        },
        { timing: 3000 }
      );

      this.get(
        '/admin/indikers/:id',
        (schema, request) => {
          const { id } = request.params;
          const result = schema.find('indiker', id);
          // @ts-ignore
          const indications = result?.attrs.indicationIds.map(
            (indicationId: any) => {
              const indicationResult = schema.find('indication', indicationId);
              if (indicationResult) {
                const indication = indicationResult.attrs;
                const contactIds = indication.contactIds as string[];
                const contacts = contactIds.map((contactId) => {
                  const contact = schema.find('contact', contactId);
                  return contact?.attrs;
                });
                const stIds = indication.statusTransactionIds as string[];
                const statusTransactions = stIds.map((stId) => {
                  const st = schema.find('statusTransaction', stId);
                  if (!st) return;
                  const statusTransaction = st.attrs;
                  const status = schema.find(
                    'indicationStatus',
                    // @ts-ignore
                    statusTransaction.indication_status_id
                  );
                  Object.assign(statusTransaction, {
                    indication_status: status?.attrs,
                  });
                  return statusTransaction;
                });
                Object.assign(indication, {
                  contacts,
                  status_transactions: statusTransactions,
                });
                return indication;
              }
              return null;
            }
          );
          // @ts-ignore
          const tags = result?.attrs.crm.tagIds.map((tagId) => {
            const tag = schema.find('tag', tagId);
            return tag?.attrs;
          });
          const indiker = {
            ...result?.attrs,
            indications,
            crm: { ...result?.crm, tags },
          };
          return new Response(200, headers, indiker);
        },
        { timing: 3000 }
      );

      this.put(
        '/admin/indikers/:id',
        (schema, request) => {
          const id = request.params.id;
          const attrs = JSON.parse(request.requestBody);
          const indiker = schema.find('indiker', id);
          if (!indiker) {
            return new Response(404, headers, { error: 'Indiker não achado' });
          }
          const { crm } = indiker;
          if (crm.crm_status != attrs.crm_status) {
            Object.assign(crm, { crm_status: attrs.crm_status });
          }
          if (crm.observation !== attrs.observation) {
            Object.assign(crm, { observation: attrs.observation });
          }
          if (crm.quality !== attrs.quality) {
            Object.assign(crm, { quality: attrs.quality });
          }
          if (crm.tagIds !== attrs.tagIds) {
            Object.assign(crm, { tagIds: attrs.tagIds });
          }
          indiker.update('crm', crm);
          return new Response(200, headers, JSON.stringify(indiker));
        },
        { timing: 3000 }
      );

      this.get('/admin/tags', (schema) => {
        // @ts-ignore
        const tags = schema.tags.all();
        return new Response(200, headers, tags.models);
      });

      this.get(
        '/admin/indication-statuses',
        (schema) => {
          // @ts-ignore
          const statuses = schema.indicationStatuses.all();
          return new Response(200, headers, statuses.models);
        },
        { timing: 1500 }
      );

      this.get('/admin/indiker-crm-statuses', (schema) => {
        // @ts-ignore
        const statuses = schema.crmStatuses.all();
        return new Response(200, headers, statuses.models);
      });

      this.get(
        '/admin/withdraw-payments',
        (schema, request: Request) => {
          const page = parseInt(request.queryParams.page, 10);

          if (page === 0) {
            console.error('======================');
            console.warn('Retornando arquivo CSV');
            console.error('======================');
          }

          const limit = parseInt(request.queryParams.limit, 10);
          const from = (page - 1) * limit;
          const to = from + limit;

          // @ts-ignore
          const result = schema.withdraws.all();
          const data = result.models.map((child: any) => {
            const { attrs } = child;
            //@ts-ignore
            const indiker = schema.indikers.find(attrs.indikerId);
            return { ...attrs, user: indiker };
          });
          const paginatedData = data.slice(from, to);
          const response: Partial<ListDTO<IndikerDTO>> = {
            data: paginatedData,
            current_page: page,
            total: result.length,
          };
          return new Response(200, headers, response);
        },
        { timing: 2000 }
      );

      this.put(
        '/admin/withdraw-payments/:id',
        (schema, request) => {
          const id = request.params.id;
          const attrs = JSON.parse(request.requestBody);
          const withdrawPayment = schema.find('withdraw', id);
          if (!withdrawPayment) {
            return new Response(404, headers, {
              error: 'Pagamento não achado',
            });
          }
          if (withdrawPayment.status != attrs.status) {
            withdrawPayment.update('status', attrs.status);
          }
          if (attrs.observation) {
            withdrawPayment.update('observation', attrs.observation);
          }
          return new Response(200, headers, JSON.stringify(withdrawPayment));
        },
        { timing: 1000 }
      );
    },
  });
}
