import React, { useEffect, useState } from 'react';
import { Card, Typography } from '@material-ui/core';
import { ReactComponent as IndicationIcon } from '../../assets/icon_indicar.svg';
import { IoIosContacts } from 'react-icons/io';
import { MdAttachMoney } from 'react-icons/md';

import { useStyles } from './styles';
import { useToast } from '../../context/toast';
import api from '../../services/api';
import { Skeleton } from '@material-ui/lab';

interface MetricsDTO {
  total_indications: number;
  total_indication_contacts: number;
  total_indication_contracts_finished: number;
}

interface InfosProps {
  indikerId: string;
}

const Loader: React.FC = () => (
  <Skeleton variant="rect" width="100%" height="72px" />
);

const Infos: React.FC<InfosProps> = ({ indikerId }) => {
  const [metrics, setMetrics] = useState<MetricsDTO | null>(null);
  const { addToast } = useToast();
  const classes = useStyles();

  useEffect(() => {
    const params = new URLSearchParams();
    params.set('user', indikerId);
    api
      .get<MetricsDTO>('/admin/indication-metrics', { params })
      .then((result) => {
        setMetrics(result.data);
      })
      .catch((error) => {
        console.error(error);
        addToast({
          message: 'Erro carregando as métricas do indiker',
          type: 'error',
        });
      });

    return () => setMetrics(null);
  }, [indikerId, addToast]);

  return (
    <>
      {metrics?.hasOwnProperty('total_indications') ? (
        <Card className={classes.infoCard}>
          <IndicationIcon />
          <Typography>{metrics.total_indications} indicações</Typography>
        </Card>
      ) : (
        <Loader />
      )}
      {metrics?.hasOwnProperty('total_indication_contacts') ? (
        <Card className={classes.infoCard}>
          <IoIosContacts />
          <Typography>{metrics.total_indication_contacts} Contatos</Typography>
        </Card>
      ) : (
        <Loader />
      )}
      {metrics?.hasOwnProperty('total_indication_contracts_finished') ? (
        <Card className={classes.infoCard}>
          <MdAttachMoney />
          <Typography>
            {metrics.total_indication_contracts_finished} Contratos fechados
          </Typography>
        </Card>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default Infos;
