import React, { useCallback } from 'react';
import { IconButton, Typography } from '@material-ui/core';
import { Rating as MuiRating } from '@material-ui/lab';
import { FiFilter, FiMinusCircle } from 'react-icons/fi';

interface RatingProps {
  stars: number;
  onSubmit(value: number): void;
}

const Rating: React.FC<RatingProps> = ({ stars, onSubmit }) => {
  const handleRatingChange = useCallback(
    (value: number | null) => {
      if (value !== stars) onSubmit(value || 0);
    },
    [onSubmit, stars]
  );

  return (
    <div>
      <Typography component="div" className="menu-section">
        <FiFilter />
        Qualidade
        <IconButton
          className="remove-btn"
          title="Remover filtro"
          onClick={() => handleRatingChange(0)}
        >
          <FiMinusCircle />
        </IconButton>
      </Typography>
      <MuiRating
        name="quality"
        value={stars}
        size="small"
        onChange={(_, value) => handleRatingChange(value)}
      />
    </div>
  );
};

export default Rating;
