import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FaChevronLeft } from 'react-icons/fa';

export const useStyles = makeStyles({
  root: {
    minWidth: 'unset',
    marginLeft: 10,
    marginBottom: 25,

    '& .MuiButton-label': {
      alignItems: 'center',
    },

    '& svg': {
      height: 14,
      width: 'auto',
      marginRight: 10,
    },

    '& .MuiTypography-root': {
      fontSize: '14px',
      fontWeight: 500,
      textTransform: 'uppercase',
    },
  },
});

const BackLink: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Button onClick={history.goBack} className={classes.root}>
      <FaChevronLeft size={22} />
      <Typography component="span">voltar</Typography>
    </Button>
  );
};

export default BackLink;
