import React, { useCallback, useState } from 'react';
import {
  Card,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';

import { useStyles } from '../styles';
import Avatar from '../../../components/Avatar/index';
import { useData } from '../../../context/data';
import { useToast } from '../../../context/toast';
import api from '../../../services/api';
import { fullName, phoneFormat } from '../../../utils/format';
import { IndikerDTO } from '../../../dtos/IndikerDTO';
import { FaTrash } from 'react-icons/fa';
import DeactivateModal from './DeactivateModal';

interface DataProps {
  indiker: IndikerDTO;
  onUpdate(data: IndikerDTO): void;
}

const Data: React.FC<DataProps> = ({ indiker, onUpdate }) => {
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const { indikerCrmStatuses } = useData();
  const { addToast } = useToast();
  const classes = useStyles();

  const handleStatusChange = useCallback(
    (ev: React.ChangeEvent<{ value: unknown }>) => {
      const selectedStatus = ev.target.value as number;
      let crmStatus = indiker.crm.crm_status;
      if (typeof crmStatus === 'string') crmStatus = parseInt(crmStatus);
      if (selectedStatus !== crmStatus) {
        const crm = { crm_status: selectedStatus };
        api
          .put<IndikerDTO>(`/admin/indikers/${indiker.id}`, crm)
          .then((result) => {
            addToast({
              message: 'Status atualizado com sucesso',
              type: 'success',
            });
            onUpdate(result.data);
          })
          .catch((error) => {
            console.error(error);
            addToast({ message: 'Erro atualizando o status', type: 'error' });
          });
      }
    },
    [indiker, addToast, onUpdate]
  );

  const handleDeactivate = useCallback(
    (reason: string) => {
      const data = { active: false, block_reason: reason };
      api
        .put<IndikerDTO>(`/admin/indikers/${indiker.id}`, data)
        .then((result) => {
          addToast({
            message: 'Indiker desativado com sucesso',
            type: 'success',
          });
          onUpdate(result.data);
          setShowDeactivateModal(false);
        })
        .catch((error) => {
          console.error(error);
          addToast({ message: 'Erro desativando o Indiker', type: 'error' });
        });
    },
    [addToast, indiker.id, onUpdate]
  );

  return (
    <Card className={classes.dataCard}>
      <div className="title">
        <div>
          <Avatar
            firstname={indiker.firstname}
            lastname={indiker.lastname}
            imageUrl={indiker.image_url}
            size={53}
            fontSize="18px"
          />
          <Typography component="span" className="title-name">
            {indiker.firstname}
          </Typography>
        </div>
        {indikerCrmStatuses.length && (
          <div>
            <Select
              variant="filled"
              value={indiker.crm.crm_status}
              defaultValue={indikerCrmStatuses[0].id}
              onChange={handleStatusChange}
            >
              {indikerCrmStatuses.map((status) => (
                <MenuItem key={status.id} value={status.id}>
                  {status.title}
                </MenuItem>
              ))}
            </Select>
          </div>
        )}
      </div>
      <div className="section">
        <div>
          <Typography className="section-title">Nome Completo</Typography>
          <Typography className="section-text">
            {fullName(indiker.firstname, indiker.lastname)}
          </Typography>
        </div>
        <div>
          <Typography className="section-title">Status</Typography>
          <Typography className="section-text">
            {indiker.active ? 'Ativo' : 'Inativo'}
            {Boolean(indiker.active) && (
              <>
                <IconButton
                  onClick={() => setShowDeactivateModal(true)}
                  size="small"
                  title="Bloquear usuário"
                  style={{ marginLeft: 5 }}
                >
                  <FaTrash size={16} />
                </IconButton>
                <DeactivateModal
                  open={showDeactivateModal}
                  onClose={() => setShowDeactivateModal(false)}
                  onAccept={handleDeactivate}
                />
              </>
            )}
          </Typography>
        </div>
      </div>
      <div className="section">
        <div>
          <Typography className="section-title">E-mail</Typography>
          <Typography className="section-text">{indiker.email}</Typography>
        </div>
        <div>
          <Typography className="section-title">Telefone</Typography>
          <Typography className="section-text" align="center">
            {indiker?.phone ? phoneFormat(indiker.phone) : '-'}
          </Typography>
        </div>
      </div>
    </Card>
  );
};

export default Data;
