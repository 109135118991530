import React, { useCallback, useState } from 'react';
import { Button, IconButton, TextField, Typography } from '@material-ui/core';
import { FaRegEdit } from 'react-icons/fa';

interface ObservationProps {
  text: string | null;
  onSubmit(field: string, value: unknown): void;
}

const Observation: React.FC<ObservationProps> = ({ text, onSubmit }) => {
  const [observationEditing, setObservationEditing] = useState(false);
  const [observation, setObservation] = useState<string | null>(null);

  const handleObservationSubmit = useCallback(
    (ev: React.FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      onSubmit('observation', observation);
      setObservationEditing(false);
    },
    [observation, onSubmit]
  );

  const handleObservationEdition = useCallback(() => {
    setObservationEditing((oldObservationEditing) => !oldObservationEditing);
  }, []);

  return (
    <>
      <Typography component="div" className="title">
        Observação
        <IconButton onClick={handleObservationEdition}>
          <FaRegEdit size={16} />
        </IconButton>
      </Typography>
      {observationEditing ? (
        <form onSubmit={handleObservationSubmit}>
          <TextField
            variant="filled"
            name="observation"
            fullWidth
            multiline
            defaultValue={text}
            onChange={(ev) => setObservation(ev.currentTarget.value)}
          />
          <div className="btnContainer">
            <Button type="submit" variant="contained">
              Salvar
            </Button>
            <Button
              variant="outlined"
              onClick={() => setObservationEditing(false)}
            >
              Cancelar
            </Button>
          </div>
        </form>
      ) : (
        <Typography>{text || '-'}</Typography>
      )}
    </>
  );
};

export default Observation;
