import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    marginTop: 40,
  },
  leftMenu: {
    width: 230,
    height: '100%',
    paddingLeft: 30,
    '& .menu-section': {
      marginTop: 25,
      color: '#000000',
      fontSize: '14px',
      fontWeight: 500,
      textTransform: 'uppercase',
      '& svg': {
        marginRight: 10,
      },
    },
    '& .menu-item': {
      display: 'flex',
      justifyContent: 'flex-start',
      flex: 1,
      marginLeft: 25,
      marginTop: 5,
      '& .MuiButton-label': {
        transition: 'all 0.5s ease-in',
        fontSize: '14px',
        fontWeight: 400,
        color: '#707070',
        textTransform: 'none',
        textAlign: 'left',
        background: 'transparent',
        border: 0,
      },
      '&.active .MuiButton-label': {
        fontWeight: 700,
        color: '#0054F0',
      },
    },
    '& .remove-btn': {
      fontSize: '14px',
      color: theme.palette.error.main,
      padding: 0,
      margin: '0 0 8px 5px',
      '& svg': {
        marginRight: 0,
      },
    },
  },
  content: {
    flex: 1,
    margin: '0 20px',
  },
  dataCard: {
    width: '100%',
    padding: '30px 35px',
    '& .title': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      marginBottom: 30,
      '& div': {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 10,
      },
      '& .title-name': {
        marginLeft: 15,
        fontSize: '20px',
        fontWeight: 500,
      },
    },
    '& .section': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
      marginTop: '20px',
      '& > div:not(:last-child)': {
        marginRight: 10,
      },
    },
    '& .section-title': {
      color: '#878787',
      fontSize: '12px',
    },
    '& .section-text': {
      fontSize: '16px',
    },
  },
  annotationsCard: {
    width: '100%',
    padding: 35,
    '& .title': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '20px',
      fontWeight: 500,
      marginBottom: 30,
    },
    '& .annotations-container': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 30,
    },
    '& .btnContainer': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 15,
    },
    '& .MuiButton-root': {
      padding: '5px 15px',
    },
  },
  tagsContainer: {
    '& .MuiIconButton-root': {
      padding: 2,
    },
    '& .color-label': {
      width: 18,
      height: 16,
    },
  },
  tagsList: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& .tag': {
      borderRadius: 3,
      marginLeft: 3,
      marginBottom: 3,
      padding: '5px 10px',
      '& span': {
        fontSize: '12px',
        cursor: 'default',
        whiteSpace: 'nowrap',
      },
    },
  },
  infoCard: {
    display: 'flex',
    alignItems: 'center',
    padding: 20,
    '&:not(:first-child)': {
      marginTop: 25,
    },
    '& svg': {
      marginRight: 25,
      height: 32,
      width: 'auto',
    },
    '& .MuiTypography-root': {
      fontSize: '14px',
      fontWeight: 500,
      textTransform: 'uppercase',
    },
  },
  indicationsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItem: 'center',
    marginTop: 50,
    marginBottom: 15,
    '& .title': {
      fontSize: '20px',
      fontWeight: 500,
    },
  },
  indicationCard: {
    '& .name-container': {
      margin: '19px 24px 15px',
    },
    '& .name': {
      fontSize: '16px',
      fontWeight: 500,
    },
    '& .status': {
      fontSize: '14px',
    },
    '& .updated': {
      borderTop: '1px solid rgba(112,112,112,0.17)',
      padding: '20px 23px',
    },
    '& .updated-title': {
      textAlign: 'right',
      fontSize: '11px',
      fontWeight: 700,
    },
    '& .updated-value': {
      textAlign: 'right',
      fontSize: '11px',
    },
  },
  indicationsPagination: {
    '& .MuiTableCell-root': {
      border: 0,
    },
  },
}));
