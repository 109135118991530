import React, { useCallback, useState } from 'react';
import { FaSignOutAlt } from 'react-icons/fa';

import { useStyles } from './styles';
import UserMenuItem from './UserMenuItem';
import { useAuth } from '../../../context/auth';
import { Button, List, Menu, Typography } from '@material-ui/core';
import Avatar from '../../../components/Avatar';

const UserMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { user, signOut } = useAuth();
  const classes = useStyles();

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleSignOutClick = useCallback(() => {
    signOut();
    handleClose();
  }, [handleClose, signOut]);

  return (
    <>
      <Button
        className={classes.userSection}
        onClick={(ev) => setAnchorEl(ev.currentTarget)}
      >
        <Typography component="span">{user.firstname}</Typography>
        <Avatar
          firstname={user.firstname}
          lastname={user.lastname}
          imageUrl={user.image_url}
          size={35}
          fontSize="16px"
        />
      </Button>
      <Menu
        anchorEl={anchorEl}
        elevation={3}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={classes.menuContainer}>
          <List className={classes.list}>
            <UserMenuItem
              icon={FaSignOutAlt}
              title="Sair"
              onClick={handleSignOutClick}
            />
          </List>
        </div>
      </Menu>
    </>
  );
};

export default UserMenu;
