import React, { useCallback, useState } from 'react';
import { IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { IoMdAdd } from 'react-icons/io';

import { useStyles } from './styles';
import { useData } from '../../context/data';
import { TagDTO } from '../../dtos/IndikerDTO';

interface TagsProps {
  tags: TagDTO[];
  onSubmit(field: string, value: unknown): void;
}

const Tags: React.FC<TagsProps> = ({ tags, onSubmit }) => {
  const { tagsList } = useData();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const classes = useStyles();

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClick = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const value = parseInt(ev.target.value, 10);
      const tagIds = tags.map((tag) => tag.id);
      if (ev.target.checked) {
        tagIds.push(value);
      } else {
        let idxToRemove: number | null = null;
        tagIds.forEach((tagId, idx) => {
          const id = typeof tagId === 'number' ? tagId : parseInt(tagId);
          if (id === value) {
            idxToRemove = idx;
          }
        });
        if (idxToRemove !== null) tagIds.splice(idxToRemove, 1);
      }
      onSubmit('tags', tagIds);
      handleClose();
    },
    [handleClose, onSubmit, tags]
  );

  return (
    <div className={classes.tagsContainer}>
      <Typography>Tags</Typography>
      {!tags ? (
        <Skeleton variant="rect" width="80%" height="30">
          {' '}
        </Skeleton>
      ) : (
        <div className={classes.tagsList}>
          {!tagsList || tagsList.length === 0 ? (
            <Typography component="span">Não há tags definidas</Typography>
          ) : (
            <>
              {tags.map((indikerTag) => {
                const tag = tagsList.find((item) => item.id === indikerTag.id);
                return tag ? (
                  <div
                    key={tag.id}
                    className="tag"
                    style={{ backgroundColor: `#${tag.color || 'ccc'}` }}
                  >
                    <Typography component="span">{tag?.name}</Typography>
                  </div>
                ) : null;
              })}
              <IconButton onClick={(ev) => setAnchorEl(ev.currentTarget)}>
                <IoMdAdd fontSize={16} />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={!!anchorEl}
                onClose={handleClose}
              >
                {!!tagsList.length &&
                  tagsList.map((tag) => (
                    <MenuItem key={`tag_${tag.id}`}>
                      <input
                        type="checkbox"
                        value={tag.id}
                        checked={!!tags.find((item) => item.id === tag.id)}
                        onChange={handleClick}
                      />
                      <div
                        className="color-label"
                        style={{
                          width: 18,
                          height: 16,
                          background: `#${tag.color || 'ccc'}`,
                        }}
                      >
                        {' '}
                      </div>
                      {tag.name}
                    </MenuItem>
                  ))}
              </Menu>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Tags;
