import React from 'react';
import { AppBar, Toolbar } from '@material-ui/core';

import { useStyles } from './styles';
import Menu from './Menu';
import UserMenu from './UserMenu';
import Logo from '../../../components/Logo';

const Header: React.FC = () => {
  const classes = useStyles();

  return (
    <AppBar position="fixed" className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <Logo color="contrast" />
        <Menu />
        <UserMenu />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
