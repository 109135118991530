import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  leftMenu: {
    width: 230,
    paddingLeft: 30,
    '& .menu-title': {
      marginTop: 100,
      fontSize: '16px',
      color: '#707070',
      '& svg': {
        marginRight: 10,
      },
    },
    '& .menu-section': {
      marginTop: 25,
      color: '#000000',
      fontSize: '14px',
      fontWeight: 500,
      textTransform: 'uppercase',
      '& svg': {
        marginRight: 10,
      },
      '& .remove-btn': {
        fontSize: '14px',
        color: theme.palette.error.main,
        padding: 0,
        margin: '0 0 8px 5px',
        '& svg': {
          marginRight: 0,
        },
      },
    },
    '& .menu-item': {
      display: 'flex',
      justifyContent: 'flex-start',
      flex: 1,
      marginLeft: 25,
      marginTop: 5,
      '& span': {
        fontSize: '14px',
        fontWeight: 400,
        color: '#707070',
      },
      '&.active': {
        '& span': {
          fontWeight: 700,
        },
      },
    },
  },
  mainArea: {
    marginTop: 40,
    marginLeft: 20,
    marginRight: 35,
    flex: 1,
  },
  headerContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 25,
  },
  title: {
    fontSize: '20px',
    fontWeight: 500,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiInputLabel-root': {
      color: '#757575',
    },
    '& .MuiInputBase-input': {
      color: '#757575',
    },
    '& button': {
      marginLeft: 20,
      '& .MuiButton-label': {
        color: '#757575',
      },
    },
  },
  exportBtn: {
    '& svg': {
      marginRight: 10,
    },
  },
  tableContainer: {
    width: '100%',
    '& .name-cell': {
      display: 'flex',
      '& .MuiAvatar-root': {
        marginRight: 15,
      },
    },
    '& .no-results': {
      textAlign: 'center',
      padding: 20,
    },
    '& .MuiIconButton-root': {
      padding: 5,
    },
  },
  viewContainer: {
    '& .MuiPaper-root': {
      background: '#F2F5F8',
    },
    '& .MuiCard-root': {
      background: '#FFFFFF',
    },
    '& .MuiDialogContent-root': {
      padding: 50,
    },
    '& .MuiDialogActions-root': {
      padding: 20,
    },
    '& .title': {
      fontSize: 14,
      fontWeight: 500,
    },
    '& .name': {
      fontSize: '24px',
      fontWeight: 500,
      whiteSpace: 'nowrap',
    },
    '& .info': {
      marginTop: 50,
    },
    '& .data': {
      fontSize: '14px',
      color: '#676767',
      whiteSpace: 'nowrap',
    },
    '& .confirmation': {
      padding: 15,
      marginTop: 15,
      '& .confirmation-label': {
        fontSize: '12px',
        fontWeight: 500,
        textAlign: 'center',
        marginBottom: '15px',
      },
      '& .buttons': {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 15,
        '& .MuiButton-root': {
          padding: '10px 20px',
          '&.primary': {
            background: '#A7FF75',
          },
        },
        '& .MuiButton-label': {
          fontSize: '12px',
        },
      },
    },
    '& .cards': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      marginTop: 50,
      '& .MuiCard-root': {
        padding: 30,
        marginTop: 30,
      },
      '& .primary-card': {
        background: '#005DFF',
        color: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& .value': {
          fontSize: '20px',
          fontWeight: 700,
        },
      },
    },
  },
}));
