import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Card,
  Chip,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Rating, Skeleton } from '@material-ui/lab';

import { useStyles } from './styles';
import Avatar from '../../components/Avatar/index';
import TableData from '../../components/TableData';
import { useData } from '../../context/data';
import { fullName, getDateBr, phoneFormat } from '../../utils/format';
import { IndikerDTO } from '../../dtos/IndikerDTO';

interface TableProps {
  indikers: IndikerDTO[];
  currentPage: number;
  rowsPerPage: number;
  total: number;
  loading: boolean;
  setCurrentPage(page: number): void;
  setRowsPerPage(pages: number): void;
}

const Table: React.FC<TableProps> = ({
  indikers,
  currentPage,
  rowsPerPage,
  total,
  loading,
  setCurrentPage,
  setRowsPerPage,
}) => {
  const { indikerCrmStatuses } = useData();
  const history = useHistory();
  const classes = useStyles();

  const handleRowClick = useCallback(
    (id: number) => {
      history.push(`indiker/${id}`);
    },
    [history]
  );

  return (
    <TableContainer component={Card} className={classes.tableContainer}>
      {indikers.length === 0 ? (
        <Typography component="div" className="no-results">
          Sem resultados
        </Typography>
      ) : (
        <MuiTable size="small">
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell align="center">Qualidade</TableCell>
              <TableCell align="center">Data de Entrada</TableCell>
              <TableCell align="center">Jornada</TableCell>
              <TableCell align="center">Telefone</TableCell>
              <TableCell align="center">Indicações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {indikers.map((indiker) => (
              <TableRow
                key={`indiker_${indiker.id}`}
                hover
                onClick={() => handleRowClick(indiker.id)}
                className={!indiker.active ? 'inactive-user' : undefined}
              >
                <TableData classname="name-cell" loading={loading}>
                  <Avatar
                    firstname={indiker.firstname}
                    lastname={indiker.lastname}
                    imageUrl={indiker.image_url}
                    size={29}
                  />
                  {fullName(indiker.firstname, indiker.lastname)}
                  {!indiker.crm.viewed && (
                    <Chip label="Novo" color="secondary" />
                  )}
                </TableData>
                <TableData align="center" loading={loading}>
                  <Rating
                    value={indiker.crm.quality || 0}
                    size="small"
                    readOnly
                  />
                </TableData>
                <TableData align="center" loading={loading}>
                  {getDateBr(indiker.created_at)}
                </TableData>
                <TableData align="center" loading={loading}>
                  {(() => {
                    const status = indikerCrmStatuses.find(
                      (item) => item.id === indiker.crm.crm_status
                    );
                    return status?.title;
                  })()}
                </TableData>
                <TableData align="center" loading={loading}>
                  {indiker.phone ? phoneFormat(indiker.phone) : '-'}
                </TableData>
                <TableData align="center" loading={loading}>
                  {indiker.indications?.length}
                </TableData>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              {loading ? (
                <TableCell colSpan={6}>
                  <Skeleton
                    width="300px"
                    height={40}
                    style={{ float: 'right' }}
                  />
                </TableCell>
              ) : (
                <TablePagination
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  labelRowsPerPage="Resultados por página"
                  colSpan={6}
                  count={total}
                  page={currentPage}
                  onPageChange={(_, newPage) => setCurrentPage(newPage)}
                  onRowsPerPageChange={(ev) =>
                    setRowsPerPage(parseInt(ev.target.value, 10))
                  }
                />
              )}
            </TableRow>
          </TableFooter>
        </MuiTable>
      )}
    </TableContainer>
  );
};

export default Table;
