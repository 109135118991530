import React, { useCallback, useEffect, useState } from 'react';
import {
  Card,
  Grid,
  InputAdornment,
  Table,
  TableFooter,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { FaSearch } from 'react-icons/fa';

import { useStyles } from './styles';
import { FilterName, FiltersProps } from './props';
import { useToast } from '../../context/toast';
import api from '../../services/api';
import { getDateBr } from '../../utils/format';
import { IndicationDTO } from '../../dtos/IndicationDTO';
import { ListDTO } from '../../dtos/ResultsDTO';
import { Skeleton } from '@material-ui/lab';

interface IndicationsProps {
  indikerId: string;
  filters: FiltersProps;
  onFilterChange: (name: FilterName, value: unknown) => void;
}

const Indications: React.FC<IndicationsProps> = ({
  indikerId,
  filters,
  onFilterChange,
}) => {
  const [indications, setIndications] = useState<IndicationDTO[]>([]);
  const [searchFilter, setSearchFilter] = useState(filters.search);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [appliedFilters, setAppliedFilters] = useState(filters);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  const classes = useStyles();

  useEffect(() => {
    if (filters !== appliedFilters) {
      setCurrentPage(0);
      setAppliedFilters(filters);
    }
  }, [filters, appliedFilters]);

  useEffect(() => {
    setLoading(true);
    const params = new URLSearchParams();
    params.set('user', indikerId);
    params.set('page', (currentPage + 1).toString());
    params.set('limit', rowsPerPage.toString());

    Object.entries(appliedFilters).forEach(([key, value]) => {
      if (value) params.set(key, value);
    });

    api
      .get<ListDTO<IndicationDTO>>('/admin/indications', { params })
      .then((result) => {
        setIndications(result.data.data);
        setTotal(result.data.total);
      })
      .catch((error) => {
        console.error(error);
        addToast({
          message: 'Error carrgando os dados do indiker',
          type: error,
        });
      })
      .finally(() => {
        setLoading(false);
      });

    return () => setIndications([]);
  }, [addToast, currentPage, appliedFilters, indikerId, rowsPerPage]);

  const handleFormSubmit = useCallback(
    (ev: React.FormEvent<unknown>) => {
      ev.preventDefault();
      onFilterChange('search', searchFilter);
    },
    [onFilterChange, searchFilter]
  );

  const handleRowsPerPageChange = useCallback((pages: number) => {
    setRowsPerPage(pages);
    setCurrentPage(0);
  }, []);

  return (
    <>
      <div className={classes.indicationsHeader}>
        <Typography className="title">Indicações</Typography>
        <form onSubmit={handleFormSubmit}>
          <TextField
            name="indication_name"
            label="Nome da empresa"
            variant="filled"
            onChange={(ev) => setSearchFilter(ev.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <FaSearch />
                </InputAdornment>
              ),
            }}
          />
        </form>
      </div>
      {!loading ? (
        <Grid container spacing={2}>
          {indications?.map((indication) => {
            const statusTransaction = indication.status_transactions
              ? indication.status_transactions.find(
                  (item) =>
                    item.indication_status_id === indication.current_status
                )
              : null;

            return (
              <Grid key={`indic_${indication.id}`} item xs={12} md={6} lg={3}>
                <Card className={classes.indicationCard}>
                  <div className="name-container">
                    <Typography className="name">{indication.name}</Typography>
                    <Typography className="status">
                      {statusTransaction?.indication_status.name || '-'}
                    </Typography>
                  </div>
                  <div className="updated">
                    <Typography className="updated-title">
                      Atualizado
                    </Typography>
                    <Typography className="updated-value">
                      {statusTransaction?.created_at
                        ? getDateBr(statusTransaction.created_at)
                        : '-'}
                    </Typography>
                  </div>
                </Card>
              </Grid>
            );
          })}
          <Table size="small" className={classes.indicationsPagination}>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  labelRowsPerPage="Resultados por página"
                  colSpan={6}
                  count={total}
                  page={currentPage}
                  onPageChange={(_, newPage) => setCurrentPage(newPage)}
                  onRowsPerPageChange={(ev) =>
                    handleRowsPerPageChange(parseInt(ev.target.value, 10))
                  }
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Grid>
      ) : (
        <Skeleton
          variant="rect"
          width="100%"
          height="200px"
          style={{ marginTop: 50 }}
        />
      )}
    </>
  );
};

export default Indications;
