import React, { useCallback } from 'react';
import { IconButton, MenuItem, TextField, Typography } from '@material-ui/core';
import { IoMdPeople } from 'react-icons/io';
import { FiFilter, FiMinusCircle } from 'react-icons/fi';

import { useStyles } from './styles';
import { Filters, FilterName } from './props';
import { useData } from '../../context/data';

interface LeftMenuProps {
  onFilterChange: (name: FilterName, value: unknown) => void;
  filters?: Filters;
}

const LeftMenu: React.FC<LeftMenuProps> = ({ filters, onFilterChange }) => {
  const { withdrawStatuses } = useData();
  const classes = useStyles();

  const handleStatusChange = useCallback(
    (value: string | null) => {
      if (filters?.status !== value) onFilterChange('status', value);
    },
    [filters, onFilterChange]
  );

  return (
    <div className={classes.leftMenu}>
      <Typography component="div" className="menu-title">
        <IoMdPeople />
        Solicitações de saque
      </Typography>
      <Typography component="div" className="menu-section">
        <FiFilter />
        Filtrar por status
        <IconButton
          className="remove-btn"
          title="Remover filtro"
          onClick={() => handleStatusChange(null)}
        >
          <FiMinusCircle />
        </IconButton>
      </Typography>
      <TextField
        name="status"
        select
        fullWidth
        value={filters?.status !== null ? filters?.status : ''}
        defaultValue=""
        onChange={(ev) => handleStatusChange(ev.target.value)}
      >
        {withdrawStatuses.map((item) => (
          <MenuItem key={`menu_${item.id}`} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};

export default LeftMenu;
