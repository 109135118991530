import React, { useCallback } from 'react';
import { Card } from '@material-ui/core';

import { useStyles } from './styles';
import Observation from './Observation';
import Rating from './Rating';
import Tags from './Tags';
import { useLoading } from '../../context/loading';
import { useToast } from '../../context/toast';
import { IndikerDTO } from '../../dtos/IndikerDTO';
import api from '../../services/api';

interface AnnotationsProps {
  indiker: IndikerDTO;
  onUpdate(data: IndikerDTO): void;
}

const Annotations: React.FC<AnnotationsProps> = ({ indiker, onUpdate }) => {
  const { startLoading, stopLoading } = useLoading();
  const { addToast } = useToast();
  const classes = useStyles();

  const handleSubmit = useCallback(
    (field: string, value: unknown) => {
      const crm = { [field]: value };
      startLoading();
      api
        .put<IndikerDTO>(`/admin/indikers/${indiker.id}`, crm)
        .then((result) => {
          addToast({
            message: 'Indiker atualizado com sucesso',
            type: 'success',
          });
          onUpdate(result.data);
        })
        .catch((error) => {
          console.error(error);
          addToast({
            message: 'Erro atualizando os dados do indiker',
            type: 'error',
          });
        })
        .finally(() => {
          stopLoading();
        });
    },
    [indiker, addToast, onUpdate, startLoading, stopLoading]
  );

  return (
    <Card className={classes.annotationsCard}>
      <Observation text={indiker.crm.observation} onSubmit={handleSubmit} />
      <div className="annotations-container">
        <Tags tags={indiker.crm.tags} onSubmit={handleSubmit} />
        <Rating stars={indiker.crm.quality || 0} onSubmit={handleSubmit} />
      </div>
    </Card>
  );
};

export default Annotations;
